import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

// prettier-ignore
const PtJobPost = () => import('@/entities/pt-job-post/pt-job-post.vue');
// prettier-ignore
const PtJobPostUpdate = () => import('@/entities/pt-job-post/pt-job-post-update.vue');
// prettier-ignore
const PtJobPostDetails = () => import('@/entities/pt-job-post/pt-job-post-details.vue');
// prettier-ignore
const PtJobSkill = () => import('@/entities/pt-job-skill/pt-job-skill.vue');
// prettier-ignore
const PtJobSkillUpdate = () => import('@/entities/pt-job-skill/pt-job-skill-update.vue');
// prettier-ignore
const PtJobSkillDetails = () => import('@/entities/pt-job-skill/pt-job-skill-details.vue');
// prettier-ignore
const PtAttachmentOne = () => import('@/entities/pt-attachment-one/pt-attachment-one.vue');
// prettier-ignore
const PtAttachmentOneUpdate = () => import('@/entities/pt-attachment-one/pt-attachment-one-update.vue');
// prettier-ignore
const PtAttachmentOneDetails = () => import('@/entities/pt-attachment-one/pt-attachment-one-details.vue');
// prettier-ignore
const PtText = () => import('@/entities/pt-text/pt-text.vue');
// prettier-ignore
const PtTextUpdate = () => import('@/entities/pt-text/pt-text-update.vue');
// prettier-ignore
const PtTextDetails = () => import('@/entities/pt-text/pt-text-details.vue');
// prettier-ignore
const PtJobApply = () => import('@/entities/pt-job-apply/pt-job-apply.vue');
// prettier-ignore
const PtJobApplyUpdate = () => import('@/entities/pt-job-apply/pt-job-apply-update.vue');
// prettier-ignore
const PtJobApplyDetails = () => import('@/entities/pt-job-apply/pt-job-apply-details.vue');
// prettier-ignore
const PtJobApplyAtt = () => import('@/entities/pt-job-apply-att/pt-job-apply-att.vue');
// prettier-ignore
const PtJobApplyAttUpdate = () => import('@/entities/pt-job-apply-att/pt-job-apply-att-update.vue');
// prettier-ignore
const PtJobApplyAttDetails = () => import('@/entities/pt-job-apply-att/pt-job-apply-att-details.vue');
// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'pt-job-post',
      name: 'PtJobPost',
      component: PtJobPost,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'pt-job-post/new',
      name: 'PtJobPostCreate',
      component: PtJobPostUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'pt-job-post/:ptJobPostId/edit',
      name: 'PtJobPostEdit',
      component: PtJobPostUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'pt-job-post/:ptJobPostId/view',
      name: 'PtJobPostView',
      component: PtJobPostDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'pt-job-skill',
      name: 'PtJobSkill',
      component: PtJobSkill,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'pt-job-skill/new',
      name: 'PtJobSkillCreate',
      component: PtJobSkillUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'pt-job-skill/:ptJobSkillId/edit',
      name: 'PtJobSkillEdit',
      component: PtJobSkillUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'pt-job-skill/:ptJobSkillId/view',
      name: 'PtJobSkillView',
      component: PtJobSkillDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'pt-attachment-one',
      name: 'PtAttachmentOne',
      component: PtAttachmentOne,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'pt-attachment-one/new',
      name: 'PtAttachmentOneCreate',
      component: PtAttachmentOneUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'pt-attachment-one/:ptAttachmentOneId/edit',
      name: 'PtAttachmentOneEdit',
      component: PtAttachmentOneUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'pt-attachment-one/:ptAttachmentOneId/view',
      name: 'PtAttachmentOneView',
      component: PtAttachmentOneDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'pt-text',
      name: 'PtText',
      component: PtText,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'pt-text/new',
      name: 'PtTextCreate',
      component: PtTextUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'pt-text/:ptTextId/edit',
      name: 'PtTextEdit',
      component: PtTextUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'pt-text/:ptTextId/view',
      name: 'PtTextView',
      component: PtTextDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'pt-job-apply',
      name: 'PtJobApply',
      component: PtJobApply,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'pt-job-apply/new',
      name: 'PtJobApplyCreate',
      component: PtJobApplyUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'pt-job-apply/:ptJobApplyId/edit',
      name: 'PtJobApplyEdit',
      component: PtJobApplyUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'pt-job-apply/:ptJobApplyId/view',
      name: 'PtJobApplyView',
      component: PtJobApplyDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'pt-job-apply-att',
      name: 'PtJobApplyAtt',
      component: PtJobApplyAtt,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'pt-job-apply-att/new',
      name: 'PtJobApplyAttCreate',
      component: PtJobApplyAttUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'pt-job-apply-att/:ptJobApplyAttId/edit',
      name: 'PtJobApplyAttEdit',
      component: PtJobApplyAttUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'pt-job-apply-att/:ptJobApplyAttId/view',
      name: 'PtJobApplyAttView',
      component: PtJobApplyAttDetails,
      meta: { authorities: [Authority.USER] },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  ],
};
