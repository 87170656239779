import axios from 'axios';

import buildPaginationQueryOpts from '@/shared/sort/sorts';
import { IPtJobPost } from '@/shared/model/pt-job-post.model';
import { IPtJobApply } from '@/shared/model/pt-job-apply.model';
import { IPtJobApplyAtt } from '@/shared/model/pt-job-apply-att.model';

const baseApiUrl = '/services/btiportalcorems/api';

export default class JoinBTI {
  public retrievePtJobPosts(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + '/pt-job-posts/no-auth')
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public createPtJobApply(entity: IPtJobApply): Promise<IPtJobApply> {
    return new Promise<IPtJobApply>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/pt-job-applies/no-auth`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public createPtJobApplyAtt(entity: IPtJobApplyAtt): Promise<IPtJobApplyAtt> {
    return new Promise<IPtJobApplyAtt>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/pt-job-apply-atts/no-auth`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
