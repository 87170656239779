var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("ribbon"),
      _vm._v(" "),
      _c(
        "div",
        {
          class: _vm.visibleMenuBar ? "d-block" : "d-none",
          attrs: { id: "app-header" },
        },
        [_c("jhi-navbar")],
        1
      ),
      _vm._v(" "),
      _c("div", [_c("router-view")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }