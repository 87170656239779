import Vue from 'vue';
import Component from 'vue-class-component';
import Ribbon from '@/core/ribbon/ribbon.vue';
import JhiFooter from '@/core/jhi-footer/jhi-footer.vue';
import JhiNavbar from '@/core/jhi-navbar/jhi-navbar.vue';

import '@/shared/config/dayjs';

@Component({
  components: {
    ribbon: Ribbon,
    'jhi-navbar': JhiNavbar,

    'jhi-footer': JhiFooter,
  },
})
export default class App extends Vue {
  // * ============= inject services =============
  // * ============= inject services =============

  // * ============= variables =============
  public visibleMenuBar = false;
  // * ============= variables =============

  public mounted() {
    this.$root.$emit('onVisibleMenuBar', false);
  }
  public created() {
    this.$root.$off('onVisibleMenuBar');
    this.$root.$on('onVisibleMenuBar', value => {
      this.visibleMenuBar = value;
    });
  }
}
