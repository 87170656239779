import { Authority } from '@/shared/security/authority';
const PAppComponents = () => import('@/components/app-components.vue');
const BTINavbar = () => import('@/components/bti-navbar/bti-navbar.vue');
const BTIFooter = () => import('@/components/bti-footer/bti-footer.vue');

/* tslint:disable */
// prettier-ignore

// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default [


  {
    path: '/app-component',
    component: PAppComponents,
    children: [
      {
        path: 'bti-navbar',
        name: 'BTINavbar',
        component: BTINavbar,
      },
      {
        path: 'bti-footer',
        name: 'bti-footer',
        component: BTIFooter,
      },
    ],
  },

  // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
];
